<template>
    <div>
        <div class="title">
            <h2>GPX</h2>
        </div>
        <div>

            <Loading :active="loading" />
            <Loading :active="loadinglinha" is-full-screen />

            <div class="linha linha-content" v-for="r in regioes" :key="r.id">
                <div class="linha-conteudo" :class="{ active: r.open }">
                    <div class="regiao-linha" @click.prevent="getGpx(r)">
                        <div class="regiao-linha-text">{{ r.title }}</div>
                        <div class="badge">{{ r.count }}</div>
                    </div>
                </div>
                <collapse-transition>
                    <div v-if="r.open">
                        <div class="linha" v-for="g in r.gpx" :key="g.id">
                            <div v-if="g.regioes_id">
                                <div class="regioes">
                                    <div class="gtitle">{{ g.title }}</div>
                                    <div class="regioes-actions">
                                        <div class="flipper btn-container">
                                            <a class="btn btn-down2" href="#" @click.prevent="createAndOpenFile(g)"
                                                style="font-size: 10px">
                                                <img src="../assets/download.png"
                                                    style="height: 10px;filter: invert(100%);" />
                                                Android
                                            </a>
                                        </div>
                                        <div class="flipper btn-container">
                                            <a class="btn btn-down2" href="#" @click.prevent="createAndOpenFileIos(g)"
                                                style="font-size: 10px">
                                                <img src="../assets/download.png"
                                                    style="height: 10px;filter: invert(100%);" />
                                                Ios
                                            </a>
                                        </div>
                                        <div class="flipper btn-container">
                                            <a :class="{ 'btn-active': g.open }" class="btn btn-down2" href="#"
                                                @click.prevent="getOpen(g)">
                                                <img src="../assets/map.png"
                                                    style="height: 20px;filter: invert(100%);" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <Mapa v-if="g.open" :gpx="g.gpxView" style="margin-bottom: 15px" />
                                <hr />
                            </div>
                        </div>
                    </div>
                </collapse-transition>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Loading from '../components/Loading'
import Mapa from '../components/Mapa'
import { CollapseTransition } from '@ivanv/vue-collapse-transition'
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem'
import { Toast } from '@capacitor/toast'
import { getAnalytics, logEvent } from 'firebase/analytics'

export default {
    components: {
        Mapa,
        Loading,
        CollapseTransition
    },
    mounted() {
        const analytics = getAnalytics(this.$fire)
        logEvent(analytics, 'screen_view', {
            firebase_screen: `APP - ${this.$route.meta.lang} ${this.$route.meta.titulo}`,
            firebase_screen_class: 'GPX'
        })
        document.title = `${this.$route.meta.lang} CoordsGo.com - ${this.$route.meta.titulo}`
        this.getRegioes()
    },
    data: () => ({
        regioes: [],
        gpx: [],
        loading: false,
        loadinglinha: false
    }),
    methods: {
        getOpen(e) {
            e.open = !e.open
            this.$forceUpdate()
            this.loadinglinha = false
            return !e.open
        },
        async createAndOpenFileIos(g) {
            g.click = Number(g.click) + 1
            let gpx = g.gpx
            /* let gpx = g.gpx.split('<wpt')
            var n = 0
            gpx = gpx.map(i => {
              if (/wpt/.test(i)) {
                i = `<wpt${i}`.replace('</wpt>', `WP${n}</wpt>`)
                n = n + 1
              }
              return i
            })
            return console.log(gpx.toString()) */
            var a = window.document.createElement('a')
            a.href = window.URL.createObjectURL(
                new Blob([gpx], { type: 'text/json' })
            )
            a.download = `coordsgo.com_${g.title.split(' ').join('_')}.txt`
            try {
                await Filesystem.mkdir({
                    path: 'Download/CoordsGO',
                    directory: Directory.ExternalStorage,
                    recursive: false,
                })
            }
            catch (e) { console.log(e) }
            await Filesystem.writeFile({
                path: `Download/CoordsGO/${a.download}`,
                data: gpx,
                directory: Directory.ExternalStorage,
                encoding: Encoding.UTF8,
            })
            await Toast.show({
                text: 'Arquivo gravado em Download/CoordsGO!',
            })
            //document.body.appendChild(a)
            //a.click()

            document.body.removeChild(a)

            await axios.put(`${this.$url}/mapas/${g.id}`, {
                click: g.click
            })
            this.$forceUpdate()
        },
        async createAndOpenFile(g) {
            g.click = Number(g.click) + 1
            let gpx = g.gpx
            var a = window.document.createElement('a')
            a.href = window.URL.createObjectURL(
                new Blob([gpx], { type: 'text/json' })
            )
            a.download = `coordsgo.com_${g.title.split(' ').join('_')}.gpx`

            try {
                await Filesystem.mkdir({
                    path: 'Download/CoordsGO',
                    directory: Directory.ExternalStorage,
                    recursive: false,
                })
            }
            catch (e) { console.log(e) }
            await Filesystem.writeFile({
                path: `Download/CoordsGO/${a.download}`,
                data: gpx,
                directory: Directory.ExternalStorage,
                encoding: Encoding.UTF8,
            })
            await Toast.show({
                text: 'Arquivo gravado em Download/CoordsGO!',
            })

            //document.body.appendChild(a)
            //a.click()

            document.body.removeChild(a)

            await axios.put(`${this.$url}/mapas/${g.id}`, {
                click: g.click
            })
            this.$forceUpdate()
        },
        async getGpx(mapa) {
            mapa.gpx = []
            this.loadinglinha = true
            mapa.open = !mapa.open
            if (!mapa.open) return (this.loadinglinha = false)
            const gpx = await axios.get(`${this.$url}/mapas/all`, {
                params: { regioes_id: mapa.id }
            })
            if (gpx.data.length) {
                mapa.gpx = []
                mapa.gpx = gpx.data.map(i => {
                    let mod = i.gpx.match(/<gpx .*/, 'gmi')[0]
                    console.log(mod)
                    i.gpxView = i.gpx
                        .replace(/<gpx .*/gim, `${mod}<trk>`)
                        .replace(/<\/gpx>/gim, '</trk></gpx>')
                        .replaceAll('wpt', 'trkpt')
                    i.open = false
                    return i
                })

                this.$forceUpdate()
                this.loadinglinha = false
            }
        },
        async getRegioes() {
            this.loading = true
            const regioes = await axios.get(`${this.$url}/regioes/all`)
            this.regioes = regioes.data.map(i => {
                i.mapa = []
                i.open = false

                return i
            })
            this.loading = false
        }
    }
}
</script>

<style lang="scss" scoped>
h2 {
    display: flex;
    justify-content: space-between;
    color: #673bb7;
    margin: 10px 0;
    font-size: 21px;
}

.btn-container {
    flex: 1;
    margin: 0 3px 15px 3px;
}

.btn {
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.back {
    background-color: #ffc107;
}

.front {
    background-color: #673ab7;
}

.back,
.front {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
    height: 100%;
}

.back {
    transform: rotateY(180deg);
}

.back,
.front {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.back,
.flipper,
.front {
    transform-style: preserve-3d;
    border-radius: 5px;
}

.btn-content {
    margin: 0 auto;
}

.linha {
    text-align: left !important;
    margin: 10px 0;
}

.linha-content {
    border: 1px solid #ccc;
    border-radius: 5px;

    .badge {
        background: #673bb7;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        color: #fff;
        font-weight: normal;
        font-size: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .linha-conteudo {
        padding: 10px 25px;
        cursor: pointer;
    }

    .regiao-linha-text {
        flex: 1;
    }

    .active {
        background: #673bb7;
        border-radius: 5px 5px 0 0;

        .regiao-linha {
            color: #fff;
        }

        .badge {
            background: #fff;
            color: #673bb7;
        }
    }
}

.regiao-title {
    text-align: left;
    font-size: 20px;
    text-decoration: none;
    color: #2c3e50;
}

.regioes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.regioes-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.gtitle {
    font-size: 12px;
    margin-left: 15px;
}

.btn-down2 {
    background: #673bb7;
    color: #fff;
    text-decoration: none;
    height: 50px;
    flex: 1;
    width: 60px;
    border-radius: 5px;
}

.regiao-linha {
    color: #2c3e50;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    justify-content: space-around;
}

.btn-active {
    background: #ffc108;
}

hr {
    border-bottom: 1px dashed #ccc;
    border-style: dashed;
}
</style>
